@import "./icons.scss";


// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";


body {
  * {
    outline: none;
  }
}

#sidebar-menu {
  ul {
    li {
      a {
        i {
          min-width: 2rem;
        }
      }
    }
  }
}

.leaflet-pane {
  z-index: 1;
}
svg > rect:first-child {
  fill-opacity: 0 !important;
}
.dataTables_filter,
.dataTables_paginate {
  float: right;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
  padding-left: 15px;
}
.dz-message {
  text-align: center;
  padding: 30px;
}
// kanban board
.react-kanban-column {
  height: 100%;
  min-height: 28px;
  display: inline-block;
  padding: 15px;
  border-radius: 2px;
  background-color: rgb(238, 238, 238);
  margin: 5px;
  vertical-align: top;
  min-width: 280px;
  @media (min-width: 576px){
    width: 400px;
    min-width: 400px;
  }
}
[data-layout-mode="dark"]{
  .react-kanban-column{
    background-color: $gray-dark-300;
  }
  .sweet-alert{
    background-color: $gray-dark-300 !important;
  }
}
.fcYNFc,
.sc-AxmLO.gmtmqV {
  background-color: $card-bg !important;
  margin: 0 20px 0 0 !important;
}
.react-kanban-card-adder-button {
  width: 100%;
  margin-top: 5px;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid rgb(204, 204, 204);
  transition: all 0.3s ease 0s;
  border-radius: 3px;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #fff;
  background-color: #038edc;
  border-color: #038edc;
}
.react-kanban-card-adder-form {
  box-sizing: border-box;
  border-radius: 3px;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  margin-bottom: 7px;
}
.task-box {
  border: 1px solid $border-color;
}
.react-datepicker-wrapper {
  width: 100% !important;
}
.ReactModal__Overlay {
  z-index: 1001 !important;
}
.external-event {
  &:hover {
    cursor: pointer;
  }
}
a:hover {
  cursor: pointer;
}
.rating-container {
  background-color: transparent !important;
}
.input-group-append {
  z-index: 0;
}
.input-color {
  color: $input-color !important;
}

[data-layout-mode="dark"]{
  .input-color {
    color: $gray-dark-500 !important;
  }
}
.sketch-picker {
  position: absolute;
  z-index: 1;
}
.rangeslider__fill {
  background-color: $primary !important;
}

.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

.bs-select select {
  width: auto !important;
}

//File-manager
.file-manager {
  .table td {
    padding: 0.35rem;
  }
}

//Authenication carousel
.slider_css {
  .slide {
    background: none !important;
  }

  .carousel-status,
  .control-next,
  .control-prev {
    display: none !important;
  }
}
.carousel {
  .control-dots {
    margin: -5px 0px;
  }
  .dot {
    background: #556ee6 !important;
    width: 9px !important;
    height: 9px !important;
  }
}

//Two verification Page
.verification {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification {
    input {
      width: 40px !important;
    }
  }
}

@media (max-width: 320px) {
  .verification {
    input {
      width: 30px !important;
      height: 30px !important;
      font-size: 15px !important;
    }
  }
}

//Two verification Page - 2
.verification-2 {
  input {
    &:focus {
      border: none;
      outline: none !important;
    }
  }
}

@media (max-width: 768px) {
  .verification-2 {
    input {
      width: 40px !important;
      font-size: 14px;
    }
  }
}

@media (max-width: 320px) {
  .verification-2 {
    input {
      width: 30px !important;
      height: 35px !important;
      font-size: 15px !important;
    }
  }
}

//Calendar
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  padding: 5px !important;
  font-size: 12px !important;
  margin-right: 2px;
}

//Chat Scrollbar
.ps__rail-y {
  z-index: 999999 !important;
}
.close {
  background: transparent;
  border: 0;
  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent escape-svg($btn-close-bg) center /
    $btn-close-width auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
  span {
    display: none;
  }
}

//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $grid-gutter-width;
  }

  @include media-breakpoint-up(sm) {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}


/* drawer */
.react-drawer-drawer {
  z-index: 9999;
  background: #fff;
}
.drawer-main-menu {
  padding-top: 8px;
  padding-bottom: 8px;
}
.drawer-menu a i {
  min-width: 2rem;
  display: inline-block;
  min-width: 1.75rem;
  padding-bottom: 0.125em;
  font-size: 1.25rem;
  line-height: 1.40625rem;
  vertical-align: middle;
  color: #7f8387;
  transition: all 0.4s;
}
.drawer-menu a {
  display: block;
  padding: 0.625rem 1.5rem;
  color: #545a6d;
  position: relative;
  font-size: 13px;
  transition: all 0.4s;
}
.drawer-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}
.ReactModal__Overlay {
  z-index: 5000 !important;
}
.rangeslider__handle:focus {
  outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
  float: right !important;
}
.search-label {
  float: right;
}
.react-drawer-overlay {
  z-index: 9999;
}
.react-drawer-drawer{
  width: 280px;
}

.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-bottom___2f9G_-sass,
.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__drawer-top___1dfbB-sass{
  width: 100% !important;
}


// datatable

.react-bs-table-sizePerPage-dropdown {
  .dropdown-menu {
    .dropdown-item {
      a {
        display: block;
      }
    }
  }
}
.rightbar-title .right-bar-toggle {
  background-color: #444c54 !important;
  height: 24px !important;
  width: 24px !important;
  line-height: 24px !important;
  display: block !important;
  color: #eff2f7 !important;
  text-align: center !important;
  border-radius: 50% !important;
}

//rightbar drawer
.css-sourceMap-modules-importLoaders-1-localIdentName-animate__animated___2O131 {
  animation-duration: 0.6s !important;
}
.css-sourceMap-modules-importLoaders-1-localIdentName-ReactDrawer__overlay___2QFmC-sass {
  background: rgba(0, 0, 0, 0.15) !important;
}

.carousel-indicators li {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  -webkit-transition: opacity .6s ease;
  transition: opacity .6s ease;
}

.carousel-dark .carousel-indicators li{
  background-color: #000;
}

.swiper-pagination-progressbar{
  width: 100% !important;
}

.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}

.colorpicker-input-addon{
  display: inline-block;
  padding: 4px;
}

.row-result tr{
    font-weight: 500;
}

.row-home-winner td:nth-child(n+2):nth-child(-n+4){
    color: green;
}

.row-home-winner td:nth-child(n+7):nth-child(-n+9){
    color: red;
}

.row-home-loser td:nth-child(n+2):nth-child(-n+4){
    color: red;
}

.row-home-loser td:nth-child(n+7):nth-child(-n+9){
    color: green;
}


.row-home-winner-closed td:nth-child(n+3):nth-child(-n+5){
    color: green;
}

.row-home-winner-closed td:nth-child(n+6):nth-child(-n+8){
    color: red;
}

.row-home-loser-closed td:nth-child(n+3):nth-child(-n+5){
    color: red;
}

.row-home-loser-closed td:nth-child(n+6):nth-child(-n+8){
    color: green;
}


.clr-red{
  color: red;
}

.clr-green{
    color: green;
}

.clr-yellow{
    color: yellow;
}

.bold-text{
    font-weight: 500;
}

.border-right-1{
    border-right: 1px solid #0b0d0f;
}

.border-bottom-1{
    border-bottom: 1px solid #0b0d0f;
}

.w-30 {
    width: 30% !important;
}

.w-375 {
    width: 37.5% !important;
}

.w-40 {
    width: 40% !important;
}

.filter-block-group{
    width: 250px;
    margin-right: 5px;
}

.filter-block-group-1{
    width: 300px;
    margin-right: 5px;
}

.filter-block-group-2{
    width: 140px;
    margin-right: 5px;
}

.filter-block-group-3{
    width: 170px;
    margin-right: 10px;
}

.filter-block-group-4{
    width: 285px;
    margin-right: 5px;
}

.filter-block{
    font-size: 11px;
}

.filter-block-1{
    float: left;
    width: 90px;
    font-size: 11px;
}

.filter-clear{
    position: absolute;
    width: 50px;
    font-size: 30px;
    margin-top: 30px;
    cursor: pointer;
    padding-left: 0;
}

.select-filter{
    width: 100%;
    text-align: center;
}

.select-filter .react-select__value-container {
    margin-left: 14px;
}

.font-size-30{
    font-size: 30px;
}

.see-strategy{
    font-size: 20px;
    cursor: pointer;
}

.clr-orange-500{
    color: $orange-500
}

.clr-blue-500{
    color: $blue-500
}

.select-status{
    margin: auto;
    width: 100px;
    text-align: center;
}

.select-status .react-select__menu{
    width: 100px!important;
    text-align: center;
}

.blink {
    color: transparent;
    -webkit-animation: blink 800ms step-end infinite;
    animation: blink 800ms step-end infinite;
}
@-webkit-keyframes blink { 50% { color: orange; }}
@keyframes blink { 50% { color: orange; }}

/*=============*/

.pulse {
    color: transparent;
    -webkit-animation: blink 800ms infinite;
    animation: blink 800ms infinite;
}
@-webkit-keyframes blink { 50% { color: orange; }}
@keyframes blink { 50% { color: orange; }}

/*=============*/

.pulse2 {
    -webkit-animation: pulse 400ms infinite alternate;
    animation: pulse 400ms infinite alternate;
}
@-webkit-keyframes pulse {
    0% { color: orange; }
    100% { color: transparent; }
}
@keyframes pulse {
    0% { color: orange; }
    100% { color: transparent; }
}

.w-cell-team{
  min-width: 200px;
}

.result-col-max-width .col{
  max-width: 30px;
  text-align: center;
}

.max-width-time-alert{
    max-width: 100px;
}

.video-bg{
    position: absolute;
    left: 50%; /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%); /* % of current element */
}
